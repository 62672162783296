import React, { useEffect } from 'react'

import styled from '@emotion/styled'

import { pageAnimation2 } from 'core/animation'
import { LegalPage, LegalParagraph, LegalTitle } from 'sections/legals/Legals'
import { mq, rem } from 'styles/helpers'
import CloseButton from 'components/close-button/CloseButton'
import tc from 'helpers/tc'

const Subtitle = styled.h3`
  font-size: ${rem(40)};
  font-weight: bold;
  margin-bottom: .875em;

  ${mq.tabletP} {
    font-size: ${rem(30)};
  }

  * + & {
    margin-top: 1.75em;
  }
`
const Smalltitle = styled(Subtitle)`
  font-size: ${rem(32)};

  ${mq.tabletP} {
    font-size: ${rem(26)};
  }
`

const Minititle = styled(Subtitle)`
  font-size: ${rem(25)};

  ${mq.tabletP} {
    font-size: ${rem(21)};
  }
`

const anim = pageAnimation2()

const Privacy = () => {
  useEffect(() => { tc.page({ page: 'protection_des_donnees_personnelles' }) }, [])

  return (
    <LegalPage { ...anim }>
      <CloseButton />

      <LegalTitle> Politique de protection des données personnelles </LegalTitle>
      <LegalParagraph> Cette politique de protection des données à caractère personnel fixe la manière dont les données des Utilisateurs sont traitées dans le cadre de ce site. </LegalParagraph>
      <LegalParagraph> Les données collectées pourront faire l'objet de traitement, automatisé ou non, conformément au règlement général sur la protection des données « RGPD » du 27 avril 2016 et dans les conditions attachées à chaque support de collecte ou service, décrites dans la présente politique de protection des données à caractère personnel. </LegalParagraph>
      <LegalParagraph> Conformément à la règlementation relative à la protection des données à caractère personnel, les Participants au Jeu sont informés que les données à caractère personnel les concernant sont collectées par PACIFICA, en sa qualité de responsable de traitement et sont obligatoires pour participer au Jeu et prétendre à la remise du Lot, s’il y a lieu. Le défaut de communication de ces données aura pour conséquence l’impossibilité pour PACIFICA de traiter votre demande de participation. </LegalParagraph>
      <LegalParagraph> Ces données seront nécessaires afin de permettre à l’Utilisateur de participer au jeu et de permettre l’envoi des lots aux gagnants, le cas échéant. Leur collecte repose sur l’intérêt légitime de PACIFICA.      </LegalParagraph>
      <LegalParagraph> Les données sont conservées pendant la durée du jeu. </LegalParagraph>

      <Subtitle> Modalités d’exercice des droits par les Utilisateurs </Subtitle>
      <LegalParagraph> Conformément à la réglementation relative à la protection des données à caractère personnel en vigueur, l’Utilisateur dispose :</LegalParagraph>
      <LegalParagraph as='ul'>
        <li> D’un droit d’accès, de rectification et d’effacement de ses données, </li>
        <li> D’un droit d’opposition au traitement de ses données pour des raisons tenant à sa situation particulière, </li>
        <li> Du droit de retirer son consentement à tout moment lorsque celui-ci est requis, </li>
        <li> Du droit à la limitation du traitement de ses données </li>
        <li> Du droit à la portabilité de ses données, </li>
        <li> Du droit de communiquer sur le sort de ses données en cas de décès. </li>
      </LegalParagraph>
      <LegalParagraph> Ces droits peuvent être exercés, en justifiant de son identité, sur simple demande écrite adressée par courrier à l’adresse suivante :  </LegalParagraph>
      <LegalParagraph css={ { textAlign: 'center' } }> PACIFICA <br /> Délégué à la Protection des Données <br /> 8/10 boulevard de Vaugirard <br /> 75724 Paris Cedex 15 </LegalParagraph>
      <LegalParagraph> Ou par courrier électronique à l’adresse suivante : <a href='mailto:donnees.personnelles@ca-pacifica.fr'>donnees.personnelles@ca-pacifica.fr</a> </LegalParagraph>
      <LegalParagraph> En cas de demande formulée par courrier postal, les frais de timbre seront remboursés sur simple demande de l’Utilisateur.  </LegalParagraph>
      <LegalParagraph> En cas de contestation, les Utilisateurs peuvent former une réclamation auprès de la CNIL dont le site internet est accessible à l’adresse suivante <a href='http://www.cnil.fr' target='_blank' rel='noreferrer'>http://www.cnil.fr</a> et le siège social est situé 3 Place de Fontenoy, 75007 Paris.  </LegalParagraph>

      <Subtitle> Destinataires des données </Subtitle>
      <LegalParagraph> Pour les besoins du présent traitement, les données collectées dans le cadre du jeu sont destinées à PACIFICA. La liste des destinataires pourra vous être communiquée sur simple demande de votre part en écrivant par lettre simple au DPO dont l’adresse figure ci-dessus. </LegalParagraph>

      <LegalTitle> Cookies </LegalTitle>
      <LegalParagraph> Lors de la consultation de notre site internet, des informations relatives à votre navigation sont susceptibles d'être enregistrées dans des fichiers "Cookies" installés sur votre terminal (ordinateur, tablette, smartphone…), sous réserve de vos choix exprimés concernant les cookies et que vous pouvez modifier à tout moment.Les informations ci-dessous vous permettent de mieux comprendre le fonctionnement des cookies et l'utilisation des outils actuels afin de les paramétrer. </LegalParagraph>

      <Subtitle> Qu'est-ce qu'un cookie ? </Subtitle>
      <LegalParagraph> Un cookie est un petit fichier texte, image ou logiciel, contenant des informations qui est enregistré sur le disque dur de votre terminal (ex : ordinateur, tablette, smartphone, ou tout autre appareil permettant de naviguer sur internet) à l’occasion de la consultation d’un site internet. Il est transmis par le serveur d’un site Internet à votre navigateur. Le fichier cookie porte notamment sur les pages visitées, les publicités sur lesquelles vous avez cliquées, le type de navigateur que vous utilisez, votre adresse IP, et permet à son émetteur d’identifier le terminal dans lequel il est enregistré, pendant la durée de validité du cookie concerné. </LegalParagraph>
      <LegalParagraph> Comme indiqué sur le bandeau d’information du site, seuls les cookies acceptés lors de votre première navigation sur le site sont susceptibles d’être déposés sur votre terminal. Vous pouvez à tout moment revenir sur cette décision en vous opposant au dépôt des cookies (voir ci-dessous : comment gérer les cookies).  </LegalParagraph>

      <Subtitle> À quoi sert un cookie ? </Subtitle>
      <LegalParagraph> Les cookies ont plusieurs fonctions. </LegalParagraph>
      <LegalParagraph> Ils facilitent notamment votre navigation sur notre site internet et améliorent considérablement sa convivialité. Les cookies, sous réserve de vos choix exprimés concernant les cookies et que vous pouvez modifier à tout moment, peuvent vérifier si votre terminal de navigation (ordinateur, tablette, smartphone…) a déjà été connecté à notre site et de conserver certaines informations de votre passage. Ces informations permettront d’améliorer votre navigation lors de la visite suivante. </LegalParagraph>
      <LegalParagraph> Certains cookies techniques sont indispensables pour le bon fonctionnement du site internet. Leur suppression peut entraîner des difficultés de navigation ainsi que l’impossibilité d’utiliser certains services proposés par notre site Internet. </LegalParagraph>

      <Subtitle> Quels sont les cookies utilisés sur notre site ? </Subtitle>
      <LegalParagraph> Notre site Internet utilise différentes catégories de cookies. Certains cookies techniques (cookies de session notamment) sont strictement nécessaires au bon fonctionnement de notre site internet. Leur suppression peut entraîner des difficultés de navigation. Les cookies sont conservés pendant une durée n’excédant pas 13 mois, sauf suppression préalable de votre part (voir ci-dessous : comment gérer les cookies ?). </LegalParagraph>

      <Subtitle> Les cookies techniques et fonctionnels </Subtitle>
      <LegalParagraph> Ces cookies permettent d’accéder, grâce à vos identifiants personnels (cookie identifiant de session), à certains espaces réservés, de mettre en œuvre des mesures de sécurité (lorsqu’il vous est par exemple demandé de vous identifier à nouveau après un certain délai d’inactivité), de permettre ou de faciliter la communication en vue de la fourniture des services proposés par notre site (accès à vos espaces personnels notamment). </LegalParagraph>
      <LegalParagraph> Ces cookies sont indispensables et strictement nécessaires pour vous permettre de naviguer sur notre site et d’utiliser ses fonctionnalités. Ils nous permettent également d’adapter la présentation de notre site aux préférences d’affichage de votre terminal.  </LegalParagraph>
      <LegalParagraph> Ces cookies peuvent être paramétrés au niveau du navigateur. Néanmoins, si vous choisissez de désactiver les cookies au niveau du navigateur, notre site peut fonctionner différemment. </LegalParagraph>
      <LegalParagraph> Certains cookies sont également nécessaires à l’enregistrement et au respect de préférences et de vos options notamment concernant le refus d’ajouter d’autres types de cookies sur votre appareil. Si vous supprimez les cookies au niveau du navigateur, celui utilisé pour reconnaître vos options sera également supprimé, vous obligeant à sélectionner de nouveau vos préférences. </LegalParagraph>
      <LegalParagraph> Ces cookies sont émis et déposés par PACIFICA. Ils ont une durée de vie très courte, pour la majorité, le temps de la cession, et au maximum une année. </LegalParagraph>

      <Subtitle> Les cookies de performance et de mesure d’audience </Subtitle>
      <LegalParagraph> Ces cookies recueillent des informations sur la façon dont les visiteurs utilisent un site internet (nombre de visites, les pages les plus visitées, les contenus les plus consultés, etc.). Ces cookies permettent d’établir des statistiques d’analyse de la fréquentation de notre site internet et d’en améliorer les fonctionnalités. </LegalParagraph>
      <LegalParagraph> Google Analytics, l'outil de statistiques utilisé par PACIFICA, génère un cookie avec un identifiant unique, dont la durée de conservation est limitée à 13 mois. Les données recueillies ne sont pas cédées à des tiers ni utilisées à d’autres fins. </LegalParagraph>

      <Subtitle> Comment gérer les cookies ?</Subtitle>
      <LegalParagraph> Plusieurs possibilités vous sont offertes pour gérer les cookies. Vous pouvez faire le choix à tout moment d’exprimer et de modifier vos souhaits en matière de cookies, par les moyens décrits ci-dessous. </LegalParagraph>

      <Smalltitle> 1. Paramétrage de votre navigateur </Smalltitle>
      <LegalParagraph> Vous avez la possibilité de naviguer sur notre site et de vous opposer à l’utilisation des cookies. Pour cela, vous devez sélectionner une option sur votre navigateur telle que « bloquer/interdire les cookies ». Leur suppression peut entraîner des difficultés voire une impossibilité de navigation ainsi que l’impossibilité de souscrire en ligne à nos offres. </LegalParagraph>
      <LegalParagraph> Vous avez également la possibilité de supprimer à tout moment les cookies déjà présents dans votre terminal. Néanmoins, tout paramétrage que vous pouvez entreprendre sera susceptible de modifier votre navigation sur Internet et vos conditions d’accès à certains services nécessitant l’utilisation de Cookies. </LegalParagraph>
      <LegalParagraph> Vous pouvez configurer votre logiciel de navigation de manière à ce que des cookies soient enregistrés dans votre terminal ou au contraire qu’ils soient rejetés soit systématiquement, soit selon leur émetteur. Vous pouvez également configurer votre logiciel de navigation de manière à ce que l’acceptation ou le refus des cookies vous soient proposés ponctuellement, avant qu’un cookie soit susceptible d’être enregistré dans votre terminal. </LegalParagraph>
      <LegalParagraph> La configuration de chaque navigateur est différente. Elle est décrite dans le menu d’Aide de votre navigateur. </LegalParagraph>

      <Minititle> Pour Chrome </Minititle>
      <LegalParagraph> <a target='_blank' rel='noreferrer' href='https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en'>https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en</a> </LegalParagraph>

      <Minititle> Pour Internet Explorer </Minititle>
      <LegalParagraph> <a target='_blank' rel='noreferrer' href='https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies'>https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies</a> </LegalParagraph>

      <Minititle> Pour Firefox </Minititle>
      <LegalParagraph> <a target='_blank' rel='noreferrer' href='https://support.mozilla.org/fr/kb/cookies-informations-sites-enregistrent'>https://support.mozilla.org/fr/kb/cookies-informations-sites-enregistrent</a> </LegalParagraph>

      <Minititle> Pour Safari </Minititle>
      <LegalParagraph> <a target='_blank' rel='noreferrer' href='https://support.apple.com/fr-fr/guide/safari/sfri11471/mac'>https://support.apple.com/fr-fr/guide/safari/sfri11471/mac</a> </LegalParagraph>

      <Minititle> Pour Opera </Minititle>
      <LegalParagraph> <a target='_blank' rel='noreferrer' href='https://help.opera.com/en/latest/web-preferences/#cookies'>https://help.opera.com/en/latest/web-preferences/#cookies</a> </LegalParagraph>

      <Smalltitle> 2. Vos choix exprimés en ligne directement depuis notre site : les modalités d’opt-out </Smalltitle>
      <LegalParagraph> Vous pouvez également paramétrer depuis notre site vos choix relatifs aux cookies que nous déposons sur votre terminal, en utilisant cliquant sur le bouton ci-dessous. En cas de refus du cookie, il sera enregistré dans votre terminal un cookie ayant pour unique objet de désactiver les services associés au cookie. </LegalParagraph>
      <LegalParagraph> Attention, la prise en compte de votre souhait repose sur un cookie. Si vous supprimez tous les cookies enregistrés dans votre terminal, via votre navigateur, nous ne saurons plus que vous avez choisi cette option. </LegalParagraph>

      <LegalTitle> Sécurité </LegalTitle>
      <LegalParagraph> La préoccupation de PACIFICA est de préserver la qualité, la confidentialité et l’intégrité des données à caractère personnel des Utilisateurs. Les technologies et les politiques de sécurité appliquées permettent de protéger ces données contre tout accès non autorisé, toute utilisation impropre. Les différentes personnes qui ont accès aux données à caractère personnel des Utilisateurs sont tenues au respect de leur vie privée et à la confidentialité de leurs données personnelles. </LegalParagraph>
      <LegalParagraph> PACIFICA ne demandera jamais aux Utilisateurs de se connecter à leur banque en ligne ou de fournir des données à caractère confidentielle (numéro de carte bancaire, code...). </LegalParagraph>
      <LegalParagraph> L’Utilisateur reconnaît qu’il est impossible de garantir une sécurité totale aux données transmises sur le réseau internet et qu’il lui appartient également de mettre en œuvre tous moyens utiles aux fins de préserver la confidentialité de ses données à caractère personnel.  </LegalParagraph>
      <LegalParagraph> En cas de demande d’information suspecte, il est recommandé de ne saisir aucune information et d’en avertir PACIFICA à l’adresse suivante : <a href='mailto:avis-clients@ca-pacifica.fr'>avis-clients@ca-pacifica.fr</a> </LegalParagraph>

    </LegalPage>
  )
}

export {
  Subtitle as PrivacySubtitle,
  Smalltitle as PrivacySmalltitle,
  Minititle as PrivacyMinititle
}

export default React.memo(Privacy)
